<div class="img-wrapper">
    <!-- <div *ngIf="products.price > products.displayPrice" class="ml-auto text-center sale-d">
        <img style="width:50px; height:50px;" src="../../../../../assets/images/logo/sale.jpeg" alt="">
       <span class="text-white mr3"> %{{maths}}</span> 
    </div> -->
    <div class="front">
        <a [routerLink]="['/product/', products.handleUrl]">
            <img alt="" class="img-fluid pd-5" [src]="products.image[0]?.url">
        </a>
    </div>
    <div class="cart-info cart-wrap">
        <!-- <button (click)="addToCart(products)" title="Add to cart"><i class="fa fa-shopping-cart" ></i></button>
        <a (click)="addToWishlist(products)" title="Add to Wishlist"><i class="fa fa-heart-o" aria-hidden="true"></i></a>
        <a routerLinkActive="active" (click)="addToCompare(products)" title="Compare"><i class="fa fa-refresh"aria-hidden="true"></i></a> -->
        <a href="javascript:void(0)" title="Hızlı İncele" (click)="openQuickPreview(products.id)"><i class="fa fa-search" aria-hidden="true"></i></a>
    </div>
</div>

<div *ngIf="learnshow == 1">
    <div class="product-detail">
        <a [routerLink]="['/product/', products.handleUrl]">
            <h6>{{products.title}}</h6>
        </a>
        <p>{{products.subtitle}}</p>
        <h4 >{{products.displayPrice}} TL</h4>
    </div>
</div>

<h6 *ngIf="learnshow == 0" class="text-center my-3">{{products.title}}</h6>
<div class="text-center">
    <del *ngIf="products.price > products.displayPrice" class="text-center">{{products.price}} TL</del>
</div>

<h4 *ngIf="learnshow == 0" class="text-center">{{products.displayPrice}} TL</h4>