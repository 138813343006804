<div class="">
    <div class="col-10 mx-auto">
        <owl-carousel-o [options]="ecombannerOptions" class="">
            <ng-template carouselSlide *ngFor="let banner of bannerList">

                <img [src]="banner.ResimUrl" class="img-fluid" alt="" id="asdasd">
                <!-- <div class="item-class" [ngStyle]="{'background-image': 'url('+ banner.ResimUrl +')'}" (click)="navigateBanner(banner)">
                </div> -->
            </ng-template>
        </owl-carousel-o>

    </div>
</div>
<section class="ecommerce pt-0 ecommerce-home">

    <div class="container mt-5 mb-5">
        <h2 class="fonta text-center">Koleksiyonlar</h2>
        <div class="row">


            <div class="col-4 my-auto" *ngFor="let collection of xList;index as i">
                <ng-container *ngFor="let a of collection">
                    <div *ngIf="i == 1" class="main-wrapper mouse" [routerLink]="['/collection/' + a.HandleUrl]">
                        <img alt="#" class="bg-img img-fluid zoom" [src]="a.ResimUrl">
                    </div>
                    <div *ngIf="i != 1 && i < 3" class="col-6 mx-auto mt-4">
                        <div class="main-wrapper mouse" [routerLink]="['/collection/' + a.HandleUrl]">
                            <img alt="#" class="bg-img img-fluid zoom" [src]="a.ResimUrl">
                        </div>
                    </div>
                    <div *ngIf="i > 2" class="row mx-auto">
                        <div class="col-4 mx-auto mt-4">
                            <div class="main-wrapper mouse" [routerLink]="['/collection/' + a.HandleUrl]">
                                <img alt="#" class="bg-img img-fluid" [src]="a.ResimUrl">
                            </div>
                        </div>
                    </div>
                </ng-container>

            </div>




            <!-- <div class="col-md-4 my-auto " *ngFor="let collection of collectionList">
                <div class="col-6 mx-auto">
                    <div class="main-wrapper mouse" [routerLink]="['/collection/' + collection.HandleUrl]">
                        <img  alt="#" class="bg-img img-fluid" [src]="collection.ResimUrl">
                    </div>
                </div>

                <div class="col-6 mx-auto">
                    <div class="main-wrapper mouse" [routerLink]="['/collection/' + collection.HandleUrl]">
                        <img  alt="#" class="bg-img img-fluid" [src]="collection.ResimUrl">
                    </div>
                </div>
               
            </div> -->


        </div>
    </div>
</section>