export class Constants {
    public static get siteTitle(): string { return `samsungreyon`; };
    public static get siteUrl(): string { return `https://samsungreyon.com`; };
    public static get guid(): string { return "04A2916F-6E17-4144-861D-F70B0DDAF693"; };
    public static get apiId(): number { return 44; };
    public static get platformId(): number { return 19; };
    public static get gloparkApiUrl(): string { return `https://servis.glopark.com/api-b2c/api/b2c/${this.guid}`; };
    public static get b2cApiUrl(): string { return `https://servis.glopark.com/api-b2c/api/b2c/firma/${this.guid}`; };
    public static get pureB2cApiUrl(): string { return `https://servis.glopark.com/api-b2c/api/b2c`; };
    public static get contactsApiUrl(): string { return `https://servis.glopark.com/api-contacts/api/firma/${this.guid}`; };
    public static get b2bApiUrl(): string { return `https://servis.glopark.com/api-b2c/api/b2b/firma/${this.guid}`; };
    public static get orderApiUrl(): string { return `https://servis.glopark.com/api-order-v2/api/firma/${this.guid}`; };
    public static get circleApiUrl(): string { return `https://servis.glopark.com/api-circle/api/firma/${this.guid}`; };
    public static get Authorization(): string { return `Basic bW9iaW1ib19kZXY6YTV6eGZ0N1Ah`; };
    public static get successUrl(): string { return `https://pay.glopark.com`; };
    public static get errorUrl(): string { return `https://pay.glopark.com`; };

    public static get orderPrefix(): string { return `samsungreyon`; };
}