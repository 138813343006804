import { Component } from '@angular/core';
import { AppService } from './shared/service/app.service';

@Component({
  selector: 'app-root',
  templateUrl: './app.component.html',
  styleUrls: ['./app.component.scss']
})
export class AppComponent {
  title = 'SAMSUNG REYON';

  uuid = localStorage.getItem('uuid');

  constructor(
    private appService:AppService
  ) {
    if (!this.uuid) {
      localStorage.setItem('uuid', Math.random().toString());
    }

    this.appService.settings().subscribe(res => { });
  }
}
