import { Component, OnInit } from '@angular/core';
import { BannerService } from 'src/app/shared/service/banners';
import { CollectionService } from 'src/app/shared/service/collection.service';
import { Router } from '@angular/router';

@Component({
  selector: 'app-ecommerce-banner-slider',
  templateUrl: './ecommerce-banner-slider.component.html',
  styleUrls: ['./ecommerce-banner-slider.component.scss']
})
export class EcommerceBannerSliderComponent implements OnInit {
  bannerList;
  collectionList;
  constructor(
    private bannerService: BannerService,
    private collectionService: CollectionService,
    private router: Router
  ) {
    this.bannerList = [];
    this.collectionList = [];
  }

  getBanners() {
    this.bannerService.list().subscribe(res => {
      this.bannerList = res;
    })
  }

  xList = [[], [], [], []];

  getCollections() {
    this.collectionService.listTree().subscribe(res => {
      this.collectionList = this.collectionService.getMainCollection;
      console.log('1')
      for (let index = 0; index < this.collectionList.length; index++) {
        console.log('2')

        const element = this.collectionList[index];

        if (index == 0) this.xList[1].push(element);
        else if (index > 0 && index < 3) this.xList[0].push(element);
        else if (index > 2 && index < 5) this.xList[2].push(element);
        else this.xList[3].push(element);
        console.log('3')

      }
      console.log('cola', this.collectionList)
      console.log('xList', this.xList)
    })
  }

  ngOnInit() {
    this.getBanners();
    this.getCollections();
  }

  ecombannerOptions = {
    items: 1,
    nav: false,
    navClass: ['owl-prev', 'owl-next'],
    navText: ['<i class="icon-angle-left"></i>', '<i class="icon-angle-right"></i>'],
    navContainer: 'asdasd',
    dots: false,
    autoplay: true,
    slideSpeed: 300,
    loop: true
  }

  navigateBanner(banner) {
    if (banner.YonlendirmeURL) {
      if (banner.YonlendirmeTipi == '0') {
        this.router.navigate([`/collection/${banner.YonlendirmeURL}`]);
      } else if (banner.YonlendirmeTipi == '1') {
        this.router.navigate([`/product/${banner.YonlendirmeURL}`]);
      } else { }
    }
  }

}